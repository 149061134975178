import React, { useState } from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"

interface ITagSelector {
  bg: string;
  isSelected: boolean;
}

const ExtraData = styled.h4`
    display: inline-block;
    margin: 0 0 0.5rem 0;
    color: #999;

    @media (max-width: 992px) {
      display: block;
    }
`
const ExternalYearSeparator = styled.h5`
  margin: 0.25rem 0; 
`
const List = styled.ul`
`
const ListItem = styled.li`
  margin: 0 0 0 2rem;
`
const ShowMore = styled.div`
    margin: 0 15px;
    border: 2px solid #999;
    padding: 5px;
    width: fit-content;

    &:hover {
      cursor: pointer;
      background: #999;
    }
`
const TagSelector = styled.div<ITagSelector>`
    font-size: 90%;
    padding: 7px;
    margin: 0.15em 0.25em;
    display: inline-block;
    color: ${props => props.isSelected ? props.bg : `#FFF`};
    background: transparent;
    border-bottom: ${props => props.isSelected ? `2px solid ${props.bg}` : '1px dotted #FFF'};
    font-weight: 800;

    &:hover {
      cursor: pointer;
      color: ${props => props.bg};
      border-bottom: 1px dotted ${props => props.bg};
    }

    &:last-of-type {
      margin-bottom: 2em;
    }
`
const FilterText = styled.div`
    display: block;
    margin: 1em 0;
    font-weight: bolder;
    font-size: 70%;
`

function getBackgroundColor(stringInput: string) {
  let stringUniqueHash = [...stringInput].reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  return `hsl(${stringUniqueHash % 360}, 65%, 75%)`;
}

const IndexPage = (props: any) => {
  const [isShowAllBlog, setIsShowAllBlog] = useState(false)
  const [blogTagFilter, setBlogTagFilter] = useState<string>("")

  const { data } = props
  const entries = data.allMarkdownRemark.edges
  const projectPosts = entries.filter((p: any) => p.node.frontmatter.type === "project")
  const blogPosts = entries.filter((p: any) => p.node.frontmatter.type === "blog").sort()

  const externalEntries = data.allExternal.edges
  let lastYearPrinted = parseInt("0");

  // Get the unique tags from the blogPosts
  let blogPostTags: string[] = [];
  blogPosts.map((data: any, index: number) => {
    const { tags } = data.node.frontmatter;
    let allTags = tags.split(",");
    allTags.forEach((tag: string) => {
      blogPostTags.push(tag.toLowerCase());
    });
  })
  const uniqueBlogPostTags = Array.from(new Set(blogPostTags));

  return (
    <Layout>

      <h3>Published Projects</h3>
      <List>
        {projectPosts.map((data: any, index: number) => {
          const { slug, title, summary } = data.node.frontmatter;
          return(
            <ListItem key={`project-${index}`}>
              <Link key={index} to={slug}>{title}</Link> <ExtraData>{summary}</ExtraData>
            </ListItem>
          )
        })}
      </List>

      <br /><br />

      <h3>Blog</h3>
      <TagSelector bg={getBackgroundColor("all")} isSelected={blogTagFilter.toLowerCase() === "".toLowerCase()} onClick={() => setBlogTagFilter("")}>
          All
      </TagSelector>
      {uniqueBlogPostTags.map((tag: string) => {
        return(
          <TagSelector bg={getBackgroundColor(tag)} isSelected={blogTagFilter.toLowerCase() === tag.toLowerCase()} onClick={() => blogTagFilter.toLowerCase() === tag.toLowerCase() ? setBlogTagFilter("") : setBlogTagFilter(tag)}>
            &#35;{tag}
          </TagSelector>
        )
      })}

      <List>
        {blogTagFilter !== "" && <FilterText>Filtering blog posts on: &#35;{blogTagFilter}</FilterText>}
        {blogPosts.map((data: any, index: number) => {
          const { slug, title, fulldate, tags } = data.node.frontmatter;

          if(blogTagFilter !== "") {
            const blogTags = tags.split(",");
            const formattedTags = blogTags.map((tag: string) => tag.toLowerCase());

            if(formattedTags.includes(blogTagFilter.toLowerCase()) === false) {
              return;
            }
          }

          if(blogTagFilter === "" && (!isShowAllBlog && index > 10)) {
            return;
          }

          return(
            <ListItem key={`blog-${index}`}>
              <Link key={index} to={slug}>{title}</Link> <ExtraData>{fulldate}</ExtraData>
            </ListItem>
          )
        })}

        {blogTagFilter === "" && blogPosts.length > 10 
          && <ShowMore onClick={() => setIsShowAllBlog(!isShowAllBlog)}>{isShowAllBlog ? `Hide ${blogPosts.length-10} posts` : `Show ${blogPosts.length-10} more posts`}</ShowMore>}
      </List>

      <br /><br />

      <h3>External</h3>
      <List>
        {externalEntries.map((data: any, index: number) => {
          const { permalink, title, year, fulldate } = data.node;

          if(lastYearPrinted !== parseInt(year)) {
            lastYearPrinted = parseInt(year)
            return(
              <>
                <ExternalYearSeparator>{year}</ExternalYearSeparator>
                <ListItem key={`external-${index}`}>
                  <a href={permalink} target="_blank" rel="nofollow noreferer">{title}</a> <ExtraData>{fulldate}</ExtraData>
                </ListItem>
              </>
            )
          }

          return(
            <ListItem key={`external-${index}`}>
              <a href={permalink} target="_blank" rel="nofollow noreferer">{title}</a> <ExtraData>{fulldate}</ExtraData>
            </ListItem>
          )
        }, lastYearPrinted)}
      </List>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
{
  allMarkdownRemark(
    sort: {order: DESC, fields: frontmatter___date}
    filter: {frontmatter: {hidden: {eq: false}}}
  ) {
    edges {
      node {
        frontmatter {
          slug
          title
          summary
          type
          fulldate:date(formatString: "YYYY-MM-DD")
          tags
        }
      }
    }
  }

  allExternal(sort: {fields: publishDate, order: DESC}) {
    edges {
      node {
        permalink
        year:publishDate(formatString: "Y")
        fulldate:publishDate(formatString: "YYYY-MM-DD")
        title
      }
    }
  }
}
`